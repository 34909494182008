import React, { useState, useEffect } from 'react';

function App() {
    const [listening, setListening] = useState(false);
    const [message, setMessage] = useState('');
    const [conversation, setConversation] = useState([]);

    useEffect(() => {
        // Check if SpeechRecognition is available in the browser
        if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const recognition = new SpeechRecognition();

            recognition.continuous = true; // Enable continuous listening
            recognition.interimResults = false; // Enable interim results

            recognition.onstart = () => {
                setListening(true);
            };

            recognition.onerror = (event) => {
                console.error('Speech recognition error:', event.error);
            };

            recognition.onresult = (event) => {
                setMessage('')
                const transcript = Array.from(event.results).pop()[0]['transcript']
                    
                // console.log("transcript",transcript);
                setMessage(transcript);
                speakMessage(transcript)
            };

            recognition.onend = () => {
                setListening(false);
            };

            // Start recognition when the component mounts
            recognition.start();

            // Stop recognition when the component unmounts
            return () => {
                console.log('Component is unmounted');
                recognition.stop();
            };
        } else {
            console.error('Speech recognition not available');
        }
    }, []);
    useEffect(() => {
      return() => {
        speakMessage("Hello, How can I assist you today");
      }
    },[])
      const speakMessage = (text) => {
        // Check if SpeechSynthesis is available in the browser
        if ('speechSynthesis' in window) {
            const synthesis = window.speechSynthesis;
            const utterance = new SpeechSynthesisUtterance(text);
            // Prevent garbage collection of utt object
            console.log(utterance);
            synthesis.getVoices()
            utterance.addEventListener('end', () => {
              console.log('end event triggered');
            });

            // just for debugging completeness, no errors seem to be thrown though
            utterance.addEventListener('error', (err) => {
              console.log('err', err)
            });

            synthesis.speak(utterance);
            setTimeout(() => {
              console.log('finished?');
            }, 1500);
        } else {
            console.error('Speech synthesis not available');
        }
    };
    const handleSendMessage = () => {
        // Send the user message to the backend
        fetch('/api/chat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                message: message
            })
        })
        .then(response => response.json())
        .then(data => {
            // Update the conversation with the user and bot messages
            const newConversation = [...conversation, { user: message, bot: data.message }];
            setConversation(newConversation);

            // Clear the input
            setMessage('');
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleToggleListening = () => {
        setListening(!listening);
    };

    return (
        <div>
            <h1>Live Conversation</h1>
            {conversation.map((chat, index) => (
                <React.Fragment key={index}>
                    <p>User: {chat.user}</p>
                    <p>Bot: {chat.bot}</p>
                </React.Fragment>
            ))}
            <button onClick={handleToggleListening}>
                {listening ? 'Stop Listening' : 'Start Listening'}
            </button>
            <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} />
            <button onClick={handleSendMessage}>Send</button>
        </div>
    );
}

export default App;